import heldItems from "../components/BodyParts/heldItems";
import maleEyebrows from "../components/BodyParts/maleEyebrows";
import maleEyes from "../components/BodyParts/maleEyes";
import noses from "../components/BodyParts/noses";
import unisexBodies from "../components/BodyParts/unisexBodies";
import unisexHairs from "../components/BodyParts/unisexHairs";
import unisexMouths from "../components/BodyParts/unisexMouths";
import { emoji } from "../components/common";
import Emotion from "../enums/Emotion";
import Gender from "../enums/Gender";
import Emoji from "../interfaces/Emoji";
import PersonConfig from "../interfaces/PersonConfig";
import skinColors from "../static/skin-colors";
import { randomItem } from "./math-utils";

export const randomEmotion = (): Emotion =>
  randomItem([
    Emotion.angry,
    Emotion.disgusted,
    Emotion.happy,
    Emotion.sad,
    Emotion.scared,
    Emotion.surprised,
  ]);

export const randomGender = (): Gender =>
  randomItem([Gender.female, Gender.male, Gender.unisex]);

export const calcReward = (choiceCount: number, wrongCount: number) =>
  Math.ceil(choiceCount / (wrongCount + 1));

export const createRandomPersonConfig = (config?: {
  emotion?: Emotion;
  gender?: Gender;
  skinColor?: string;
  holdItem?: boolean;
}): PersonConfig => {
  const emotion = config?.emotion ?? randomEmotion();
  const gender = config?.gender ?? randomGender();
  const skinColor = config?.skinColor ?? randomItem(skinColors);
  const holdItem = config?.holdItem ?? false;

  const mouths = unisexMouths[emotion];
  const eyes = maleEyes[emotion];
  const eyebrows = maleEyebrows[emotion];

  const body = randomItem(unisexBodies);
  const Mouth = randomItem(mouths);
  const Eyes = randomItem(eyes);
  const Eyebrows = randomItem(eyebrows);
  const Hair = randomItem(unisexHairs);
  const Nose = randomItem(noses);
  const HeldItem = holdItem ? randomItem(heldItems[emotion]) : undefined;
  return {
    skinColor,
    body,
    Mouth,
    Eyes,
    Eyebrows,
    Hair,
    Nose,
    HeldItem,
  };
};

export const createEmoji = (emotion: Emotion, char: string): Emoji => ({
  char,
  component: emoji(char),
  emotion,
});
