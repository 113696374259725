import { useRecoilValue } from "recoil";
import styled from "styled-components";
import roundState from "../state/round-state";

const Container = styled.div`
  height: 1.5rem;
  background-color: ${({ theme }) => theme.text};
  position: relative;
  box-shadow: ${({ theme }) => theme.shadow(1)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bar = styled.div<{ progress: number }>`
  border-radius: 0 999px 999px 0;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: ${({ progress }) => 5 + progress * 98}%;
  background-color: ${({ theme }) => theme.bg};
  transition: width 0.5s;
`;

const Text = styled.span`
  position: relative;
  color: white;
  text-shadow: ${({ theme }) => theme.shadow(0.5)};
`;

const SessionProgress: React.FC = () => {
  const roundCount = 12;
  const round = useRecoilValue(roundState);

  return (
    <Container>
      <Bar progress={round / roundCount} />
      <Text>
        Round {round + 1} / {roundCount}
      </Text>
    </Container>
  );
};

export default SessionProgress;
