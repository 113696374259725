import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import View from "../enums/View";
import inGameLoopState from "../state/in-game-loop-state";
import playerConfigState from "../state/player-config-state";
import pointsState from "../state/points-state";
import viewState from "../state/view-state";
import skinColors, { eyeColors, hairColors } from "../static/skin-colors";
import { playerEyes, playerHairs } from "./BodyParts/playerParts";
import BodyPartWrapper from "./BodyPartWrapper";
import BuyHat from "./BuyHat";
import { Background, Button, ChoiceBox, emoji, GameLayout } from "./common";
import { createStyle } from "./Person";
import PlayerCharacter from "./PlayerCharacter";

export enum BoxView {
  buy,
  skin,
  eye,
  hair,
}

const Container = styled.div``;

const BoxViewOptions = styled.div`
  margin: 0.5rem 0;
  display: flex;
  justify-content: center;
`;

const BoxViewButton = styled.button`
  overflow: visible;
  outline: none;
  border: none;
  padding: 0;
  margin: 0 1rem;
  background: none;
  background-color: ${({ theme }) => theme.bg};
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  > * {
    filter: drop-shadow(${({ theme }) => theme.shadow(1)});
  }
`;

const SkinBlob = styled.div<{ color: string }>`
  width: 3rem;
  height: 3rem;
  margin: 0.4rem 0.4rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const OptionsRow = styled.div`
  display: flex;
  justify-content: center;
`;

const BodyPartBtn = styled.div`
  width: 7rem;
  height: 7rem;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const HomeBtn: React.FC = () => {
  const setView = useSetRecoilState(viewState);
  const inGameLoop = useRecoilValue(inGameLoopState);

  const handleClick = () => setView(inGameLoop ? View.diary : View.mainMenu);

  return (
    <Button onClick={handleClick} style={{ whiteSpace: "nowrap" }}>
      {emoji(inGameLoop ? "🗓" : "⬅")}
    </Button>
  );
};

const OptionsContainer: React.FC<{ onBoxChange: (view: BoxView) => void }> = ({
  children,
  onBoxChange,
}) => (
  <Container>
    <BoxViewOptions>
      <BoxViewButton onClick={() => onBoxChange(BoxView.skin)}>
        {emoji("✋")}
      </BoxViewButton>
      <BoxViewButton onClick={() => onBoxChange(BoxView.eye)}>
        {emoji("👀")}
      </BoxViewButton>
      <BoxViewButton onClick={() => onBoxChange(BoxView.hair)}>
        {emoji("💇‍♀️")}
      </BoxViewButton>
    </BoxViewOptions>
    {children}
  </Container>
);

const CharacterView: React.FC = () => {
  const [boxView, setBoxView] = useState(BoxView.skin);
  const [playerConfig, setPlayerConfig] = useRecoilState(playerConfigState);
  const points = useRecoilValue(pointsState);

  useEffect(() => {
    if (points >= 100) setBoxView(BoxView.buy);
  }, []);

  const onBoxChange = useCallback(
    (view: BoxView) => setBoxView(view),
    [setBoxView]
  );

  const changeSkinColor = (skinColor: string) =>
    setPlayerConfig((p) => ({ ...p, skinColor }));

  const changeHairColor = (hairColor: string) =>
    setPlayerConfig((p) => ({ ...p, hairColor }));

  const changeHair = (hairIndex: number) =>
    setPlayerConfig((p) => ({ ...p, hairIndex }));

  const changeEyeColor = (eyeColor: string) =>
    setPlayerConfig((p) => ({ ...p, eyeColor }));

  const changeEyes = (eyeIndex: number) =>
    setPlayerConfig((p) => ({ ...p, eyeIndex }));

  const renderBox = () => {
    switch (boxView) {
      case BoxView.buy:
        return <BuyHat onBoxChange={onBoxChange} />;
      case BoxView.eye:
        return (
          <OptionsContainer onBoxChange={onBoxChange}>
            <OptionsRow>
              {playerEyes.map((Eyes, i) => (
                <BodyPartBtn onClick={() => changeEyes(i)}>
                  <BodyPartWrapper
                    style={createStyle("accent", playerConfig.eyeColor)}
                  >
                    <Eyes
                      origin={[50, 50]}
                      style={{ transform: "scale(0.7)" }}
                    />
                  </BodyPartWrapper>
                </BodyPartBtn>
              ))}
            </OptionsRow>
            <OptionsRow>
              <HomeBtn />
              {eyeColors.map((color) => (
                <SkinBlob color={color} onClick={() => changeEyeColor(color)} />
              ))}
            </OptionsRow>
          </OptionsContainer>
        );
      case BoxView.hair:
        return (
          <OptionsContainer onBoxChange={onBoxChange}>
            <OptionsRow>
              {playerHairs.map((Hair, i) => (
                <BodyPartBtn onClick={() => changeHair(i)}>
                  <BodyPartWrapper>
                    <Hair
                      origin={[50, 0]}
                      offset={[0, -7]}
                      style={{ transform: "scale(0.5)" }}
                    />
                  </BodyPartWrapper>
                </BodyPartBtn>
              ))}
            </OptionsRow>
            <OptionsRow>
              <HomeBtn />
              {hairColors.map((color) => (
                <SkinBlob
                  color={color}
                  onClick={() => changeHairColor(color)}
                />
              ))}
            </OptionsRow>
          </OptionsContainer>
        );
      case BoxView.skin:
        return (
          <OptionsContainer onBoxChange={onBoxChange}>
            <OptionsRow>
              <HomeBtn />
              {skinColors.map((color) => (
                <SkinBlob
                  color={color}
                  onClick={() => changeSkinColor(color)}
                />
              ))}
            </OptionsRow>
          </OptionsContainer>
        );
    }
  };

  return (
    <GameLayout>
      <Background>
        <PlayerCharacter style={{ left: "50%", bottom: 0 }} />
      </Background>
      <ChoiceBox>{renderBox()}</ChoiceBox>
    </GameLayout>
  );
};

export default CharacterView;
