enum View {
  init,
  mainMenu,
  inGame,
  character,
  diary,
  bodyTester,
}

export default View;
