import styled from "styled-components";
import skinColors from "../static/skin-colors";
import heldItems from "./BodyParts/heldItems";
import maleEyebrows from "./BodyParts/maleEyebrows";
import maleEyes from "./BodyParts/maleEyes";
import noses from "./BodyParts/noses";
import unisexBodies from "./BodyParts/unisexBodies";
import unisexHairs from "./BodyParts/unisexHairs";
import unisexMouths from "./BodyParts/unisexMouths";
import Person from "./Person";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0.5rem;
`;

const Cell = styled.div`
  position: relative;
  overflow: hidden;
  > * {
    transform: scale(0.3);
  }
`;

const BodyTester: React.FC = () => {
  return (
    <Container>
      {unisexHairs
        .filter((v, i) => i > 10)
        .map((hair) => (
          <Cell>
            <Person
              config={{
                body: unisexBodies[0],
                Eyes: maleEyes.happy[0],
                Eyebrows: maleEyebrows.happy[0],
                Mouth: unisexMouths.happy[0],
                skinColor: skinColors[2],
                Nose: noses[1],
                Hair: hair,
                HeldItem: heldItems.surprised[1],
              }}
              style={{
                left: "50%",
                bottom: 0,
              }}
            />
          </Cell>
        ))}
    </Container>
  );
};

export default BodyTester;
