import { useRecoilValue, useSetRecoilState } from "recoil";
import styled, { css, keyframes } from "styled-components";
import GameVersion from "../enums/GameVersion";
import gameVersionState from "../state/game-version-state";
import roundState from "../state/round-state";
import winScreenState from "../state/win-screen-state";
import { emoji, FullscreenOverlay } from "./common";

const animation = keyframes`
  from {
    transform: rotate(-10deg);
  }

  to {
    transform: rotate(10deg);
  }
`;

const Emoji = styled.span<{ animate?: boolean }>`
  font-size: 8rem;
  ${({ animate }) =>
    animate
      ? css`
          animation: ${animation} 1s ease-in-out infinite alternate;
        `
      : null}

  transform-origin: 30% 70%;
  > * {
    filter: drop-shadow(${({ theme }) => theme.shadow(6)});
  }
`;

const WinScreen: React.FC = () => {
  const setRound = useSetRecoilState(roundState);
  const setWinScreen = useSetRecoilState(winScreenState);
  const gameVersion = useRecoilValue(gameVersionState);

  const handleClick = () => {
    setRound((r) => r + 1);
    setWinScreen(false);
  };

  return (
    <FullscreenOverlay onClick={handleClick}>
      <Emoji animate={gameVersion === GameVersion.fancy}>{emoji("👍")}</Emoji>
    </FullscreenOverlay>
  );
};

export default WinScreen;
