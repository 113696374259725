import { atom } from "recoil";
import PlayerConfig from "../interfaces/PlayerConfig";
import skinColors, {
  clothingColors,
  eyeColors,
  hairColors,
} from "../static/skin-colors";

const playerConfigState = atom<PlayerConfig>({
  key: "playerConfigState",
  default: {
    eyeIndex: 0,
    hairIndex: 0,
    hatIndex: -1,
    eyeColor: eyeColors[0],
    hairColor: hairColors[0],
    skinColor: skinColors[0],
    clothingColor: clothingColors[0],
  },
});

export default playerConfigState;
