import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled, { css, ThemeProvider } from "styled-components";
import GameVersion from "../enums/GameVersion";
import View from "../enums/View";
import diaryState from "../state/diary-state";
import gameVersionState from "../state/game-version-state";
import playerConfigState from "../state/player-config-state";
import pointsState from "../state/points-state";
import viewState from "../state/view-state";
import theme from "../static/theme";
import {
  loadDiary,
  loadPlayerConfig,
  loadPoints,
  saveDiary,
  savePlayerConfig,
  savePoints,
} from "../utils/store-utils";
import GameView from "./GameView";
import InitPage from "./InitPage";
import RuInitPage from "./RuInitPage";

const AppContainer = styled.div<{ center?: boolean }>`
  background-color: ${({ theme }) => theme.alt};
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;

  @media (min-aspect-ratio: 2/3) {
    display: flex;
    flex-direction: column;
    ${({ center }) =>
      center
        ? css`
            justify-content: center;
          `
        : ""}

    align-items: center;
  }
`;

function App() {
  const [points, setPoints] = useRecoilState(pointsState);
  const [diary, setDiary] = useRecoilState(diaryState);
  const [playerConfig, setPlayerConfig] = useRecoilState(playerConfigState);
  const setGameVersion = useSetRecoilState(gameVersionState);
  const [view, setView] = useRecoilState(viewState);

  useEffect(() => {
    setPoints(loadPoints());
    setDiary(loadDiary());
    const loadedConfig = loadPlayerConfig();
    if (loadedConfig) setPlayerConfig(loadedConfig);

    const pathname = window.location.pathname.replaceAll("/", "").toLowerCase();
    switch (pathname) {
      case "version-a":
        setGameVersion(GameVersion.basic);
        setView(View.mainMenu);
        break;
      case "version-b":
        setGameVersion(GameVersion.fancy);
        setView(View.mainMenu);
        break;
    }
  }, []);

  const pathname = window.location.pathname.replaceAll("/", "").toLowerCase();

  useEffect(() => savePoints(points), [points]);
  useEffect(() => saveDiary(diary), [diary]);
  useEffect(() => savePlayerConfig(playerConfig), [playerConfig]);

  return (
    <ThemeProvider theme={theme}>
      <AppContainer center={view !== View.init}>
        {view === View.init ? (
          pathname === "ru" ? (
            <RuInitPage />
          ) : (
            <InitPage />
          )
        ) : (
          <GameView />
        )}
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;
