import Emotion from "../enums/Emotion";
import Emoji from "../interfaces/Emoji";
import { createEmoji } from "../utils/game-utils";

const diaryEmojis: Record<Emotion, Emoji> = {
  angry: createEmoji(Emotion.angry, "😠"),
  disgusted: createEmoji(Emotion.disgusted, "🤢"),
  happy: createEmoji(Emotion.happy, "😄"),
  sad: createEmoji(Emotion.sad, "😢"),
  scared: createEmoji(Emotion.scared, "😱"),
  surprised: createEmoji(Emotion.surprised, "😮"),
};

export default diaryEmojis;
