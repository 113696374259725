import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import GameVersion from "../enums/GameVersion";
import View from "../enums/View";
import gameVersionState from "../state/game-version-state";
import roundState from "../state/round-state";
import tutorialState from "../state/tutorial-state";
import viewState from "../state/view-state";
import BodyTester from "./BodyTester";
import CharacterView from "./CharacterView";
import DiaryView from "./DiaryView";
import EmojiLine from "./EmojiLine";
import EmojiMatch from "./EmojiMatch";
import FaceBuild from "./FaceBuild";
import MainMenu from "./MainMenu";
import TutorialView from "./TutorialView";

const Container = styled.main`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  user-select: none;
  position: relative;

  background-color: ${({ theme }) => theme.bg};

  @media (min-aspect-ratio: 9/10) {
    width: 60vh;
    height: 95vh;
    box-shadow: ${({ theme }) => theme.shadow(8)};
    border-radius: 4vh;
  }
`;

const GameView: React.FC = () => {
  const [view, setView] = useRecoilState(viewState);
  const [round, setRound] = useRecoilState(roundState);
  const [gameVersion, setGameVersion] = useRecoilState(gameVersionState);
  const [tutorial, setTutorial] = useRecoilState(tutorialState);

  useEffect(() => {
    window.game = {
      setRound,
      setView: (name: keyof typeof View) => setView(View[name]),
      setGameVersion,
    };
  }, [setRound, setView]);

  useEffect(() => {
    const isFancy = gameVersion === GameVersion.fancy;

    if (isFancy) {
      if (round === 0) setTutorial("/tutorials/tutorial-1.mp4");
      else if (round === 4) setTutorial("/tutorials/tutorial-2.mp4");
      else if (round === 8) setTutorial("/tutorials/tutorial-3.mp4");
    }

    if (round >= 12) {
      if (isFancy) setView(View.character);
      else setView(View.diary);
      setRound(0);
    }
  }, [round]);

  const renderView = () => {
    switch (view) {
      case View.character:
        return <CharacterView />;
      case View.diary:
        return <DiaryView />;
      case View.inGame:
        if (tutorial !== null) return <TutorialView />;
        return round < 4 ? (
          <EmojiMatch />
        ) : round < 8 ? (
          <EmojiLine />
        ) : (
          <FaceBuild />
        );
      case View.mainMenu:
        return <MainMenu />;
      case View.bodyTester:
        return <BodyTester />;
    }
  };

  return <Container>{renderView()}</Container>;
};

export default GameView;
