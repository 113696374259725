import ReactShadowRoot from "react-shadow-root";
import styled from "styled-components";

const Container = styled.div`
  user-select: none;
  pointer-events: none;
`;

interface BodyPartWrapperProps {
  style?: string;
  notRelative?: boolean;
}

const BodyPartWrapper: React.FC<BodyPartWrapperProps> = ({
  children,
  style,
  notRelative,
}) => (
  <Container style={notRelative ? {} : { position: "relative" }}>
    <ReactShadowRoot>
      {style ? <style>{style}</style> : null}
      {children}
    </ReactShadowRoot>
  </Container>
);

export default BodyPartWrapper;
