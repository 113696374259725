import { ReactComponent as angry1 } from "../../assets/images/faces/male/eyebrows/angry/Asset 2.svg";
import { ReactComponent as angry2 } from "../../assets/images/faces/male/eyebrows/angry/Asset 54.svg";
import { ReactComponent as angry3 } from "../../assets/images/faces/unisex/eyebrows/angry/Asset 2.svg";
import { ReactComponent as angry4 } from "../../assets/images/faces/unisex/eyebrows/angry/Asset a.svg";
import { ReactComponent as angry5 } from "../../assets/images/faces/female/eyebrows/angry/Asset 46.svg";
import { ReactComponent as angry6 } from "../../assets/images/faces/female/eyebrows/angry/Asset 53.svg";
import { ReactComponent as disgusted1 } from "../../assets/images/faces/male/eyebrows/disgusted/Asset 5.svg";
import { ReactComponent as disgusted2 } from "../../assets/images/faces/male/eyebrows/disgusted/Asset 43.svg";
import { ReactComponent as disgusted3 } from "../../assets/images/faces/male/eyebrows/disgusted/Asset 55.svg";
import { ReactComponent as disgusted4 } from "../../assets/images/faces/unisex/eyebrows/disgusted/Asset 2.svg";
import { ReactComponent as disgusted5 } from "../../assets/images/faces/unisex/eyebrows/disgusted/Asset 5.svg";
import { ReactComponent as disgusted6 } from "../../assets/images/faces/female/eyebrows/disgusted/Asset 44.svg";
import { ReactComponent as disgusted7 } from "../../assets/images/faces/female/eyebrows/disgusted/Asset 52.svg";
import { ReactComponent as happy1 } from "../../assets/images/faces/male/eyebrows/happy/Asset 3.svg";
import { ReactComponent as happy2 } from "../../assets/images/faces/male/eyebrows/happy/Asset 56.svg";
import { ReactComponent as happy3 } from "../../assets/images/faces/unisex/eyebrows/happy/Asset 1.svg";
import { ReactComponent as happy4 } from "../../assets/images/faces/unisex/eyebrows/happy/Asset 5.svg";
import { ReactComponent as happy5 } from "../../assets/images/faces/female/eyebrows/happy/Asset 47.svg";
import { ReactComponent as happy6 } from "../../assets/images/faces/female/eyebrows/happy/Asset 51.svg";
import { ReactComponent as sad1 } from "../../assets/images/faces/male/eyebrows/sad/Asset 4.svg";
import { ReactComponent as sad2 } from "../../assets/images/faces/male/eyebrows/sad/Asset 57.svg";
import { ReactComponent as sad3 } from "../../assets/images/faces/unisex/eyebrows/sad/Asset 1.svg";
import { ReactComponent as sad4 } from "../../assets/images/faces/unisex/eyebrows/sad/Asset 6.svg";
import { ReactComponent as sad5 } from "../../assets/images/faces/female/eyebrows/sad/Asset 45.svg";
import { ReactComponent as sad6 } from "../../assets/images/faces/female/eyebrows/sad/Asset 50.svg";
import { ReactComponent as surprised1 } from "../../assets/images/faces/male/eyebrows/surprised/Asset 1.svg";
import { ReactComponent as surprised2 } from "../../assets/images/faces/male/eyebrows/surprised/Asset 58.svg";
import { ReactComponent as surprised3 } from "../../assets/images/faces/unisex/eyebrows/surprised/Asset 1.svg";
import { ReactComponent as surprised4 } from "../../assets/images/faces/unisex/eyebrows/surprised/Asset 5.svg";
import { ReactComponent as surprised5 } from "../../assets/images/faces/female/eyebrows/surprised/Asset 48.svg";
import { ReactComponent as surprised6 } from "../../assets/images/faces/female/eyebrows/surprised/Asset 49.svg";
import Emotion from "../../enums/Emotion";
import BodyPart from "../../interfaces/BodyPart";
import { createBodyPart } from "../common";

const cbp = createBodyPart;

const w = 8;

const maleEyebrows: Record<Emotion, BodyPart[]> = {
  angry: [
    cbp(angry1, 0, 0, w),
    cbp(angry2, 0, 0, w),
    cbp(angry3, 0, 0, w),
    cbp(angry4, 0, 0, w),
    cbp(angry5, 0, 0, w),
    cbp(angry6, 0, 0, w),
  ],
  disgusted: [
    cbp(disgusted1, 0, 0, w),
    cbp(disgusted2, 0, 0, w),
    cbp(disgusted3, 0, 0, w),
    cbp(disgusted4, 0, 0, w),
    cbp(disgusted5, 0, 0, w),
    cbp(disgusted6, 0, 0, w),
    cbp(disgusted7, 0, 0, w),
  ],
  happy: [
    cbp(happy1, 0, 0, w),
    cbp(happy2, 0, 0, w),
    cbp(happy3, 0, 0, w),
    cbp(happy4, 0, 0, w),
    cbp(happy5, 0, 0, w),
    cbp(happy6, 0, 0, w),
  ],
  sad: [
    cbp(sad1, 0, 0, w),
    cbp(sad2, 0, 0, w),
    cbp(sad3, 0, 0, w),
    cbp(sad4, 0, 0, w),
    cbp(sad5, 0, 0, w),
    cbp(sad6, 0, 0, w),
  ],
  scared: [],
  surprised: [
    cbp(surprised1, 0, 0, w),
    cbp(surprised2, 0, 0, w),
    cbp(surprised3, 0, 0, w),
    cbp(surprised4, 0, 0, w),
    cbp(surprised5, 0, 0, w),
    cbp(surprised6, 0, 0, w),
  ],
};

maleEyebrows.scared = maleEyebrows.sad;

export default maleEyebrows;
