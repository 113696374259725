import Emotion from "../enums/Emotion";
import Emoji from "../interfaces/Emoji";
import { createEmoji } from "../utils/game-utils";

export interface EmojiRecordEntry {
  emotion: Emotion;
  emojis: Emoji[];
}

const createEntry = (emotion: Emotion, chars: string[]): EmojiRecordEntry => ({
  emotion: emotion,
  emojis: createEmojis(emotion, chars),
});

const createEmojis = (emotion: Emotion, chars: string[]): Emoji[] =>
  chars.map((char) => createEmoji(emotion, char));

const emotions: Record<Emotion, EmojiRecordEntry> = {
  angry: createEntry(Emotion.angry, ["😠", "😡"]),
  disgusted: createEntry(Emotion.disgusted, ["🤢"]),
  happy: createEntry(Emotion.happy, ["😊", "😀", "😁", "😄", "🙂"]),
  sad: createEntry(Emotion.sad, ["😢", "😭", "😟", "😞"]),
  scared: createEntry(Emotion.scared, ["😱"]),
  surprised: createEntry(Emotion.surprised, ["😯", "😮"]),
};

export const emotionsList = Object.values(emotions);

export default emotions;
