import React from "react";
import styled from "styled-components";
import PersonConfig from "../interfaces/PersonConfig";
import ReactShadowRoot from "react-shadow-root";
import { darken, lighten } from "polished";
import BodyPartWrapper from "./BodyPartWrapper";

export const createStyle = (className: string, fill: string) =>
  `.${className} {fill: ${fill} !important;}`;

export const createSkinStyle = (color: string) =>
  [
    createStyle("skin", color),
    createStyle("skin-light", lighten(0.1, color)),
    createStyle("skin-lighter", lighten(0.2, color)),
    createStyle("skin-dark", darken(0.1, color)),
    createStyle("skin-darker", darken(0.2, color)),
  ].join(" ");

export const createNoseStyle = (color: string) =>
  [
    createStyle("skin", darken(0.055, color)),
    createStyle("skin-light", lighten(0.06, color)),
    createStyle("skin-lighter", lighten(0.1, color)),
    createStyle("skin-dark", darken(0.07, color)),
    createStyle("skin-darker", darken(0.17, color)),
  ].join(" ");

const Container = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
`;

interface PersonProps {
  style?: React.CSSProperties;
  config: PersonConfig;
  notRelative?: boolean;
}

const Person: React.FC<PersonProps> = ({
  style,
  config: { skinColor, body, Mouth, Eyes, Eyebrows, Hair, Nose, HeldItem },
  notRelative,
}) => {
  const skinStyle = createSkinStyle(skinColor);
  const noseStyle = createNoseStyle(skinColor);
  const r = (part: React.ReactElement, otherStyle?: string) => (
    <BodyPartWrapper
      notRelative={notRelative}
      style={otherStyle ? otherStyle : skinStyle}
    >
      {part}
    </BodyPartWrapper>
  );

  const Body = body[0];

  return (
    <Container style={style}>
      {r(<Body skinColor={skinColor} origin={[50, 100]} />)}
      {r(<Mouth offset={[0, -7]} skinColor={skinColor} />)}
      {r(<Eyes offset={[0, -12]} skinColor={skinColor} />)}
      {r(<Nose offset={[0, -9.8]} skinColor={skinColor} />, noseStyle)}
      {r(<Eyebrows offset={[0, -14]} skinColor={skinColor} />)}
      {r(
        <Hair
          offset={[0, -21 + body[1]]}
          skinColor={skinColor}
          origin={[50, 0]}
        />
      )}
      {HeldItem ? r(<HeldItem offset={[6.5, -4.5]} />) : null}
    </Container>
  );
};

export default Person;
