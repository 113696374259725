import { darken } from "polished";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import playerConfigState from "../state/player-config-state";
import todaysEmotionState from "../state/todays-emotion-state";
import hats from "./BodyParts/hats";
import {
  playerBody,
  playerClothes,
  playerEyes,
  playerFaces,
  playerHairs,
} from "./BodyParts/playerParts";
import BodyPartWrapper from "./BodyPartWrapper";
import { createSkinStyle, createStyle } from "./Person";

const Container = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
`;

interface PlayerCharacterProps {
  style?: React.CSSProperties;
}

const PlayerCharacter: React.FC<PlayerCharacterProps> = ({ style }) => {
  const {
    eyeColor,
    hairColor,
    clothingColor,
    skinColor,
    eyeIndex,
    hairIndex,
    hatIndex,
  } = useRecoilValue(playerConfigState);
  const emotion = useRecoilValue(todaysEmotionState);

  const skinStyle = createSkinStyle(skinColor);
  const r = (part: React.ReactElement, accent?: string) => (
    <BodyPartWrapper
      style={skinStyle + (accent ? createStyle("accent", accent) : "")}
    >
      {part}
    </BodyPartWrapper>
  );

  const Body = playerBody;
  const Face = playerFaces[emotion];
  const Hair = playerHairs[hairIndex];
  const Eyes = playerEyes[eyeIndex];
  const Clothes = playerClothes;
  const Hat = hatIndex >= 0 ? hats[hatIndex] : null;

  return (
    <Container style={style}>
      {r(
        <Clothes
          accent={clothingColor}
          skinColor={skinColor}
          origin={[50, 100]}
        />,
        clothingColor
      )}
      {r(<Body offset={[0, -3.2]} skinColor={skinColor} origin={[50, 100]} />)}
      {r(
        <Face
          accent={darken(0.15, hairColor)}
          offset={[0.1, -10.4]}
          skinColor={skinColor}
        />,
        darken(0.15, hairColor)
      )}
      {r(
        <Eyes accent={eyeColor} offset={[0, -11.5]} skinColor={skinColor} />,
        eyeColor
      )}
      {r(
        <Hair
          accent={hairColor}
          offset={[0, -18.61]}
          skinColor={skinColor}
          origin={[50, 0]}
        />,
        hairColor
      )}
      {Hat ? r(<Hat offset={[0, -12]} origin={[50, 100]} />, hairColor) : null}
    </Container>
  );
};

export default PlayerCharacter;
