import { ReactComponent as Image1 } from "../../assets/images/faces/unisex/hair/Asset 1.svg";
import { ReactComponent as Image2 } from "../../assets/images/faces/unisex/hair/Asset 2.svg";
import { ReactComponent as Image3 } from "../../assets/images/faces/unisex/hair/Asset 3.svg";
import { ReactComponent as Image4 } from "../../assets/images/faces/unisex/hair/Asset 4.svg";
import { ReactComponent as Image5 } from "../../assets/images/faces/unisex/hair/Asset 5.svg";
import { ReactComponent as Image6 } from "../../assets/images/faces/unisex/hair/Asset 6.svg";
import { ReactComponent as Image7 } from "../../assets/images/faces/unisex/hair/Asset 7.svg";
import { ReactComponent as Image8 } from "../../assets/images/faces/unisex/hair/Asset 8.svg";
import { ReactComponent as Image9 } from "../../assets/images/faces/unisex/hair/Asset 9.svg";
import { ReactComponent as Image10 } from "../../assets/images/faces/unisex/hair/Asset 10.svg";
import { ReactComponent as Image11 } from "../../assets/images/faces/unisex/hair/Asset 11.svg";
import { ReactComponent as Image12 } from "../../assets/images/faces/unisex/hair/Asset 12.svg";
import { ReactComponent as Image13 } from "../../assets/images/faces/unisex/hair/Asset 13.svg";
import { ReactComponent as Image14 } from "../../assets/images/faces/unisex/hair/Asset 14.svg";
import { ReactComponent as Image15 } from "../../assets/images/faces/unisex/hair/Asset 15.svg";
import { ReactComponent as Image16 } from "../../assets/images/faces/unisex/hair/Asset 16.svg";
import { ReactComponent as Image17 } from "../../assets/images/faces/unisex/hair/Asset 17.svg";
import { ReactComponent as Image18 } from "../../assets/images/faces/unisex/hair/Asset 18.svg";
import { ReactComponent as Image19 } from "../../assets/images/faces/unisex/hair/Asset 19.svg";
import { ReactComponent as Image20 } from "../../assets/images/faces/unisex/hair/Asset 20.svg";
import { ReactComponent as Image21 } from "../../assets/images/faces/unisex/hair/Asset 21.svg";
import { ReactComponent as Image22 } from "../../assets/images/faces/unisex/hair/Asset 22.svg";
import { ReactComponent as Image23 } from "../../assets/images/faces/unisex/hair/Asset 23.svg";
import { ReactComponent as Image24 } from "../../assets/images/faces/unisex/hair/Asset 24.svg";
import { ReactComponent as Image25 } from "../../assets/images/faces/unisex/hair/Asset 25.svg";
import { ReactComponent as Image26 } from "../../assets/images/faces/unisex/hair/Asset 26.svg";
import { ReactComponent as Image27 } from "../../assets/images/faces/unisex/hair/Asset 27.svg";
import { ReactComponent as Image28 } from "../../assets/images/faces/unisex/hair/Asset 28.svg";
import { ReactComponent as Image29 } from "../../assets/images/faces/unisex/hair/Asset 29.svg";
import { ReactComponent as Image30 } from "../../assets/images/faces/unisex/hair/Asset 30.svg";
import { createBodyPart } from "../common";

const cbp = createBodyPart;
const w = 17;
const w1 = 16;
const w2 = 15;

const unisexHairs = [
  cbp(Image1, 0, 4.6, w),
  cbp(Image2, 0, 2, w),
  cbp(Image3, 0, 0.3, w),
  cbp(Image4, 0, 2, w),
  cbp(Image5, 0, 2, w),
  cbp(Image6, 0, 2, w),
  cbp(Image7, 0, 1, w2),
  cbp(Image8, 0, 0.5, w1),
  cbp(Image9, 0, 0, w2),
  cbp(Image10, 0, 0, w2),
  cbp(Image11, 0, 1, w2),
  cbp(Image12, 0, 1, w),
  cbp(Image13, 0, 1, w2),
  cbp(Image14, 0, 1, w2),
  cbp(Image15, 0, 1.5, w2),
  cbp(Image16, 0, -0.2, w2),
  cbp(Image17, 0, 1, w2),
  cbp(Image18, 0, 0.5, w1),
  cbp(Image19, 0, 0, w1),
  cbp(Image20, 0, 0, w2),
  cbp(Image21, 0, 0, w),
  cbp(Image22, 0, 1.5, w),
  cbp(Image23, 0, 2, w),
  cbp(Image24, 0, 1, w),
  cbp(Image25, 0, 2, w2),
  cbp(Image26, 0, 2, w2),
  cbp(Image27, 0, 2, w2),
  cbp(Image28, 0, 1, w2),
  cbp(Image29, 0, 2, w2),
  cbp(Image30, 0, 2, w2),
];

export default unisexHairs;
