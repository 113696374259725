import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import Logo from "../assets/images/logo.svg";
import GameVersion from "../enums/GameVersion";
import View from "../enums/View";
import gameVersionState from "../state/game-version-state";
import inGameLoopState from "../state/in-game-loop-state";
import viewState from "../state/view-state";
import { Button, emoji } from "./common";
import PlayerCharacter from "./PlayerCharacter";

const LogoImg = styled.img`
  z-index: 10;
  max-width: 100%;
  max-height: 8rem;
  box-sizing: border-box;
  padding: max(0.5rem, 3vmin);
  user-select: none;
  pointer-events: none;
`;

const Container = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 2rem;
  gap: 0.5rem;
`;

const PlayerContainer = styled.div`
  position: relative;
  flex-grow: 1;
`;

const MainMenu: React.FC = () => {
  const setView = useSetRecoilState(viewState);
  const setInGameLoop = useSetRecoilState(inGameLoopState);
  const gameVersion = useRecoilValue(gameVersionState);

  const startGame = () => {
    setView(View.inGame);
    setInGameLoop(true);
  };

  return (
    <Container>
      <LogoImg src={Logo} alt="GameEmo" />
      <PlayerContainer>
        {gameVersion === GameVersion.fancy ? (
          <PlayerCharacter
            style={{ left: "50%", bottom: 0, transform: "scale(0.75)" }}
          />
        ) : null}
      </PlayerContainer>

      <Buttons>
        <Button altr onClick={startGame}>
          {emoji("🕹 Play")}
        </Button>
        {gameVersion === GameVersion.fancy ? (
          <Button altr onClick={() => setView(View.character)}>
            {emoji("🙎 Character")}
          </Button>
        ) : null}
        <Button altr onClick={() => setView(View.diary)}>
          {emoji("🗓 Mood Diary")}
        </Button>
      </Buttons>
    </Container>
  );
};

export default MainMenu;
