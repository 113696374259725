import styled from "styled-components";
import coinSrc from "../assets/images/coin.svg";

const Img = styled.img`
  width: 0.78em;
  height: 0.78em;
  margin: 0 0.1em;
`;

const CoinIcon: React.FC = () => <Img src={coinSrc} alt="Coin" />;

export default CoinIcon;
