import { ReactComponent as angry1 } from "../../assets/images/held-items/angry/alarm.svg";
import { ReactComponent as angry2 } from "../../assets/images/held-items/angry/thunder cloud.svg";
import { ReactComponent as disgusted1 } from "../../assets/images/held-items/disgusted/stinky shoe.svg";
import { ReactComponent as disgusted2 } from "../../assets/images/held-items/disgusted/wormy apple.svg";
import { ReactComponent as happy1 } from "../../assets/images/held-items/happy/candy.svg";
import { ReactComponent as happy2 } from "../../assets/images/held-items/happy/flowers.svg";
import { ReactComponent as sad1 } from "../../assets/images/held-items/sad/rain.svg";
import { ReactComponent as sad2 } from "../../assets/images/held-items/sad/violin.svg";
import { ReactComponent as scared1 } from "../../assets/images/held-items/scared/ghost.svg";
import { ReactComponent as scared2 } from "../../assets/images/held-items/scared/spider.svg";
import { ReactComponent as surprised1 } from "../../assets/images/held-items/surprised/present.svg";
import { ReactComponent as surprised2 } from "../../assets/images/held-items/surprised/ring.svg";
import Emotion from "../../enums/Emotion";
import BodyPart from "../../interfaces/BodyPart";
import { createBodyPart } from "../common";

const cbp = createBodyPart;

const w = 8;

const heldItems: Record<Emotion, BodyPart[]> = {
  angry: [cbp(angry1, 0, 0, w), cbp(angry2, -6, -16, w)],
  disgusted: [cbp(disgusted1, -2, 0, w), cbp(disgusted2, 0, 0, w)],
  happy: [cbp(happy1, 0, 1, 6), cbp(happy2, 0, 0, w)],
  sad: [cbp(sad1, -6, -15, w), cbp(sad2, -12, 0, 10)],
  scared: [cbp(scared1, -13, 0, w), cbp(scared2, 0, 0, w)],
  surprised: [cbp(surprised1, 0, 0, w), cbp(surprised2, 0, 1, 5)],
};

export default heldItems;
