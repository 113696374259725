import Emotion from "../enums/Emotion";
import BodyPart from "../interfaces/BodyPart";
import {
  EmojiLineRound,
  EmojiMatchRound,
  FaceBuildRound,
} from "../interfaces/Games";
import { EmojiRecordEntry, emotionsList } from "../static/emotions";
import {
  fbEyebrows,
  fbEyes,
  fbMouths,
  PartsSet,
} from "../static/face-build-part-sets";
import { randomInt, randomItem, shuffle } from "../utils/math-utils";

const filterSimilarEmojis = (
  answer: Emotion,
  list: EmojiRecordEntry[]
): EmojiRecordEntry[] => {
  if (answer === Emotion.scared)
    return list.filter((e) => e.emotion !== Emotion.surprised);
  else if (answer === Emotion.surprised)
    return list.filter((e) => e.emotion !== Emotion.scared);
  return list;
};

export const createEmojiMatchRound = (round: number): EmojiMatchRound => {
  const choiceCount = round < 3 ? 2 : round < 6 ? 3 : 4;
  const answerIndex = randomInt(0, choiceCount - 1);
  let emotions = shuffle(emotionsList);

  const answerEmotion = emotions[answerIndex].emotion;
  emotions = filterSimilarEmojis(answerEmotion, emotions);

  return {
    choices: emotions.slice(0, choiceCount).map((e) => randomItem(e.emojis)),
    answerIndex,
  };
};

export const createEmojiLineRound = (round: number): EmojiLineRound => {
  const choiceCount = round < 13 ? 3 : round < 16 ? 4 : round < 19 ? 5 : 6;
  const answerIndex = randomInt(0, choiceCount - 1);
  let emotions = shuffle(emotionsList);

  const answerEmotion = emotions[answerIndex].emotion;
  emotions = filterSimilarEmojis(answerEmotion, emotions);

  return {
    choices: emotions.slice(0, choiceCount).map((e) => randomItem(e.emojis)),
    answerIndex,
  };
};

const makeFBChoices = (
  { correctParts, wrongParts }: PartsSet,
  choiceCount: number
): BodyPart[] => {
  const answers = shuffle(correctParts).slice(0, Math.floor(choiceCount / 2));
  const wrongs = shuffle(wrongParts).slice(0, choiceCount - answers.length);
  return shuffle([...answers, ...wrongs]);
};

export const createFaceBuildRound = (round: number): FaceBuildRound => {
  const choiceCount = round < 23 ? 2 : round < 26 ? 3 : 4;
  const { emotion, emojis } = randomItem(emotionsList);
  const emoji = randomItem(emojis);

  const eyebrowChoices = makeFBChoices(fbEyebrows[emotion], choiceCount);
  const eyeChoices = makeFBChoices(fbEyes[emotion], choiceCount);
  const mouthChoices = makeFBChoices(fbMouths[emotion], choiceCount);

  return {
    eyebrowChoices,
    eyeChoices,
    mouthChoices,
    emotion,
    emoji,
  };
};
