export const randomInt = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const shuffle = <T>(list: T[]): T[] => {
  const array = [...list];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const randomItem = <T>(list: T[]): T =>
  list[randomInt(0, list.length - 1)];

export const range = (n: number) => Array.from(Array(n).keys());

export const last = <T>(array: T[]): T | undefined =>
  array.length > 0 ? array[array.length - 1] : undefined;

export const isToday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const unique = <T>(list: T[]): T[] => Array.from(new Set(list).values());
