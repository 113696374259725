import { lighten } from "polished";
import styled from "styled-components";
import logoSrc from "../assets/images/logo.svg";
import { Button } from "./common";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 64px 10px;
  box-sizing: border-box;
  max-width: 1000px;
  align-items: center;
`;

const LogoImg = styled.img`
  width: 90%;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
`;

const A = styled.a`
  font-size: 24px;
  margin: 4px 0;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  > * {
    font-size: 24px;
  }
`;

const Text = styled.section`
  color: ${({ theme }) => theme.text};
  font-size: 22px;
  max-width: 60ch;
  text-align: justify;
  a,
  b {
    color: ${({ theme }) => lighten(0.1, theme.text)};
  }
`;

const RuInitPage: React.FC = () => {
  return (
    <Container>
      <LogoImg src={logoSrc} alt="GamEmo" />
      <Text>
        <p>
          <b>GamEmo</b> - игра для обучения детей с РАС распознаванию эмоций.
          Исследование охватывает дизайнерскую часть игры и направлено на
          получение рекомендаций для будущих исследований.
        </p>
        <p>
          Мы рекомендуем протестировать игру вместе с детьми в спектре, но вы
          также можете сделать это самостоятельно. После прохождения{" "}
          <b>обеих</b> версий A и Б от начала (Play) до Дневника настроения
          (Mood Diary), пожалуйста, заполните анкету, которая не должна занять
          больше 10-15 минут.
        </p>
        <p>
          Если вы решите сделать снимки во время тестирования и хотите, чтобы
          они были включены в исследование, не стесняйтесь отправлять фотографии
          по адресу <a href="mailto:olieb@tlu.ee">olieb@tlu.ee</a> вместе с
          любыми другими вопросами.
        </p>
        <p>Спасибо и удачи!</p>
      </Text>
      <Buttons>
        <A href="/version-A">
          <Button>Версия А</Button>
        </A>
        <A href="/version-B">
          <Button>Версия Б</Button>
        </A>
        <A href="https://forms.gle/7zpMiWVknBZSVRvG8">
          <Button>Анкета</Button>
        </A>
      </Buttons>
    </Container>
  );
};

export default RuInitPage;
