import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import tutorialState from "../state/tutorial-state";
import { emoji } from "./common";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VideoContainer = styled.div`
  overflow: hidden;
  border-radius: 2rem;
  border: 0.25rem solid white;
  position: relative;
  box-sizing: border-box;
  width: 19.5rem;
`;

const Video = styled.video`
  margin: 0;
  padding: 0;
  width: 100%;
  transform: scale(1.05);
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 3rem;
`;

const Skip = styled.div`
  font-size: 3rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`;

const PlayButton = styled.button`
  margin: none;
  padding: none;
  border: none;
  outline: none;
  background: none;
  overflow: visible;
  filter: drop-shadow(${({ theme }) => theme.shadow(7)});
  font-size: 12rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const TutorialView: React.FC = () => {
  const [tutorial, setTutorial] = useRecoilState(tutorialState);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const vid = videoRef.current;
    if (vid === null) return;

    vid.addEventListener("ended", () => setTutorial(null));
  }, [videoRef.current]);

  if (tutorial === null) return null;

  const startVideo = () => {
    videoRef.current?.play();
    setIsPlaying(true);
  };

  return (
    <Container>
      <Title>{emoji("🎬")}</Title>
      <VideoContainer>
        <Video
          style={!isPlaying ? { opacity: 0.5 } : {}}
          ref={videoRef}
          src={tutorial}
          controls={false}
          autoPlay={false}
          muted={true}
        />
        {!isPlaying ? (
          <PlayButton onClick={startVideo}>{emoji("▶")}</PlayButton>
        ) : null}
      </VideoContainer>
      <Skip onClick={() => setTutorial(null)}>{emoji("⏩")}</Skip>
    </Container>
  );
};

export default TutorialView;
