import { ReactComponent as Hat1 } from "../../assets/images/player/hats/beige hat.svg";
import { ReactComponent as Hat2 } from "../../assets/images/player/hats/black beret.svg";
import { ReactComponent as Hat3 } from "../../assets/images/player/hats/black hat.svg";
import { ReactComponent as Hat4 } from "../../assets/images/player/hats/black horns.svg";
import { ReactComponent as Hat5 } from "../../assets/images/player/hats/black top hat.svg";
import { ReactComponent as Hat6 } from "../../assets/images/player/hats/blue bow.svg";
import { ReactComponent as Hat7 } from "../../assets/images/player/hats/blue feathered hat.svg";
import { ReactComponent as Hat8 } from "../../assets/images/player/hats/brown hat.svg";
import { ReactComponent as Hat9 } from "../../assets/images/player/hats/brown top hat.svg";
import { ReactComponent as Hat10 } from "../../assets/images/player/hats/cat ears.svg";
import { ReactComponent as Hat11 } from "../../assets/images/player/hats/feathered hat.svg";
import { ReactComponent as Hat12 } from "../../assets/images/player/hats/fox ears.svg";
import { ReactComponent as Hat13 } from "../../assets/images/player/hats/gray beret.svg";
import { ReactComponent as Hat14 } from "../../assets/images/player/hats/green bow.svg";
import { ReactComponent as Hat15 } from "../../assets/images/player/hats/pink bow.svg";
import { ReactComponent as Hat16 } from "../../assets/images/player/hats/pink flower.svg";
import { ReactComponent as Hat17 } from "../../assets/images/player/hats/pistacchio beret.svg";
import { ReactComponent as Hat18 } from "../../assets/images/player/hats/purple flower.svg";
import { ReactComponent as Hat19 } from "../../assets/images/player/hats/purple hat.svg";
import { ReactComponent as Hat20 } from "../../assets/images/player/hats/red beret.svg";
import { ReactComponent as Hat21 } from "../../assets/images/player/hats/red bow.svg";
import { ReactComponent as Hat22 } from "../../assets/images/player/hats/turquoise flower.svg";
import { ReactComponent as Hat23 } from "../../assets/images/player/hats/white top hat.svg";
import { createBodyPart } from "../common";

const cbp = createBodyPart;

const hats = [
  cbp(Hat1, -0.05, 0, 16.9),
  cbp(Hat2, -0.8, -2.6, 14),
  cbp(Hat3, -0.05, 0, 16.9),
  cbp(Hat4, 0.1, -3, 13.5),
  cbp(Hat5, 0, -3.25, 16),
  cbp(Hat6, -4.7, -1.2, 8),
  cbp(Hat7, -0.05, 0, 16.9),
  cbp(Hat8, -0.05, 0, 16.9),
  cbp(Hat9, 0, -3.25, 16),
  cbp(Hat10, 0.1, -3, 13.5),
  cbp(Hat11, -0.05, 0, 16.9),
  cbp(Hat12, 0.1, -3, 13.5),
  cbp(Hat13, -0.8, -2.6, 14),
  cbp(Hat14, -4.7, -1.2, 8),
  cbp(Hat15, -4.7, -1.2, 8),
  cbp(Hat16, -4.7, -1.2, 5),
  cbp(Hat17, -0.8, -2.6, 14),
  cbp(Hat18, -4.7, -1.2, 5),
  cbp(Hat19, -0.05, 0, 16.9),
  cbp(Hat20, -0.8, -2.6, 14),
  cbp(Hat21, -4.7, -1.2, 8),
  cbp(Hat22, -4.7, -1.2, 5),
  cbp(Hat23, 0, -3.25, 16),
];

export default hats;
