import { selector } from "recoil";
import Emotion from "../enums/Emotion";
import { last } from "../utils/math-utils";
import diaryState from "./diary-state";

const todaysEmotionState = selector<Emotion>({
  key: "todaysEmotionState",
  get: ({ get }) => {
    const diary = get(diaryState);
    const lastEntry = last(diary);
    return lastEntry ? lastEntry.emotion : Emotion.happy;
  },
});

export default todaysEmotionState;
