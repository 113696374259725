import { lighten } from "polished";
import styled from "styled-components";
import logoSrc from "../assets/images/logo.svg";
import { Button } from "./common";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 64px 10px;
  box-sizing: border-box;
  max-width: 1000px;
  align-items: center;
`;

const LogoImg = styled.img`
  width: 90%;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
`;

const A = styled.a`
  font-size: 24px;
  margin: 4px 0;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  > * {
    font-size: 24px;
  }
`;

const Text = styled.section`
  color: ${({ theme }) => theme.text};
  font-size: 22px;
  max-width: 60ch;
  text-align: justify;
  a,
  b {
    color: ${({ theme }) => lighten(0.1, theme.text)};
  }
`;

const InitPage: React.FC = () => {
  return (
    <Container>
      <LogoImg src={logoSrc} alt="GamEmo" />
      <Text>
        <p>
          <b>GamEmo</b> is a game for emotion recognition training for children
          with ASD. The study covers the design part of the game and strives to
          derive recommendations for future research.
        </p>
        <p>
          You're encouraged to test the game together with medium-low
          functioning children, but it's <b>not</b> a necessity. After playing{" "}
          <b>both</b> versions A &amp; B from start to the Mood Diary, please
          fill in the feedback form, which shouldn't take longer than 10-15
          minutes.
        </p>
        <p>
          In case you choose to take pictures during your testing sessions and
          want them to be included in the final report, don't hesitate to send
          the photos to <a href="mailto:olieb@tlu.ee">olieb@tlu.ee</a>, along
          with any other inquiries.
        </p>
        <p>
          Learn <b>emotions</b> and have <b>fun</b>!
        </p>
      </Text>
      <Buttons>
        <A href="/version-A">
          <Button>Version A</Button>
        </A>
        <A href="/version-B">
          <Button>Version B</Button>
        </A>
        <A href="https://forms.gle/WP45gYSApwDBCDzU6">
          <Button>Feedback form</Button>
        </A>
      </Buttons>
    </Container>
  );
};

export default InitPage;
