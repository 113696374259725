import Theme from "../interfaces/Theme";

const theme: Theme = {
  bg: "#ffce4f",
  text: "#664500",
  alt: "#81cfd5",
  shadowColor: "rgba(0,0,0,0.3)",
  shadow: (offset: number) =>
    `0 ${offset / 16}rem ${offset / 8}rem ${theme.shadowColor}`,
  vw: 100,
};

export default theme;
