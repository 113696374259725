import { ReactComponent as Image1 } from "../../assets/images/faces/male/nose/Asset 6.svg";
import { ReactComponent as Image3 } from "../../assets/images/faces/male/nose/Asset 42.svg";
import { ReactComponent as Image4 } from "../../assets/images/faces/unisex/noses/Asset 7.svg";
import { ReactComponent as Image5 } from "../../assets/images/faces/unisex/noses/Asset 8.svg";
import { ReactComponent as Image6 } from "../../assets/images/faces/unisex/noses/Asset 9.svg";
import { ReactComponent as Image7 } from "../../assets/images/faces/unisex/noses/Asset 10.svg";
import { ReactComponent as Image8 } from "../../assets/images/faces/unisex/noses/Asset 11.svg";
import { ReactComponent as Image9 } from "../../assets/images/faces/unisex/noses/Asset 39.svg";
import { ReactComponent as Image10 } from "../../assets/images/faces/unisex/noses/Asset 41.svg";
import { createBodyPart } from "../common";
import BodyPart from "../../interfaces/BodyPart";

const cbp = createBodyPart;

const noses: BodyPart[] = [
  cbp(Image1, 0, 0.2, 7),
  cbp(Image3, 0, 0.2, 7),
  cbp(Image4, 0, 0, 2),
  cbp(Image5, 0, 0, 2.5),
  cbp(Image6, 0, 0, 3),
  cbp(Image7, 0, 0, 2.5),
  cbp(Image8, 0, 0, 2),
  cbp(Image9, 0, -0.5, 3),
  cbp(Image10, 0, 0.3, 2.6),
];

export default noses;
