import angry1 from "../../assets/images/faces/unisex/mouths/angry/angry.png";
import { ReactComponent as angry2 } from "../../assets/images/faces/unisex/mouths/angry/Asset 1.svg";
import { ReactComponent as angry3 } from "../../assets/images/faces/unisex/mouths/angry/Asset 4.svg";
import { ReactComponent as angry4 } from "../../assets/images/faces/female/mouths/angry/Asset 3.svg";
import disgusted1 from "../../assets/images/faces/unisex/mouths/disgusted/Asset 5.png";
import { ReactComponent as disgusted2 } from "../../assets/images/faces/unisex/mouths/disgusted/Asset 1.svg";
import { ReactComponent as disgusted3 } from "../../assets/images/faces/unisex/mouths/disgusted/Asset 3.svg";
import { ReactComponent as disgusted4 } from "../../assets/images/faces/female/mouths/disgusted/Asset 4.svg";
import happy1 from "../../assets/images/faces/unisex/mouths/happy/Asset 3.png";
import { ReactComponent as happy2 } from "../../assets/images/faces/unisex/mouths/happy/Asset 3.svg";
import { ReactComponent as happy3 } from "../../assets/images/faces/unisex/mouths/happy/Asset 4.svg";
import { ReactComponent as happy4 } from "../../assets/images/faces/female/mouths/happy/Asset 4 (2).svg";
import sad1 from "../../assets/images/faces/unisex/mouths/sad/Asset 4.png";
import { ReactComponent as sad2 } from "../../assets/images/faces/unisex/mouths/sad/Asset 2.svg";
import { ReactComponent as sad3 } from "../../assets/images/faces/unisex/mouths/sad/Asset 3.svg";
import { ReactComponent as sad4 } from "../../assets/images/faces/unisex/mouths/sad/Asset 4.svg";
import { ReactComponent as sad5 } from "../../assets/images/faces/female/mouths/sad/Asset 4.svg";
import surprised1 from "../../assets/images/faces/unisex/mouths/surprised/surprised.png";
import { ReactComponent as surprised2 } from "../../assets/images/faces/unisex/mouths/surprised/Asset 2.svg";
import { ReactComponent as surprised3 } from "../../assets/images/faces/unisex/mouths/surprised/Asset 4.svg";
import { ReactComponent as surprised4 } from "../../assets/images/faces/female/mouths/surprised/Asset 3.svg";
import Emotion from "../../enums/Emotion";
import BodyPart from "../../interfaces/BodyPart";
import { createBodyPart } from "../common";

const cbp = createBodyPart;

const unisexMouths: Record<Emotion, BodyPart[]> = {
  angry: [
    cbp(angry1, 0, 0, 3),
    cbp(angry2, 0, 0, 3),
    cbp(angry3, 0, 0, 3),
    cbp(angry4, 0, 0, 3),
  ],
  disgusted: [
    cbp(disgusted1, 0, 0, 5),
    cbp(disgusted2, 0, 0, 5),
    cbp(disgusted3, 0, 0, 5),
    cbp(disgusted4, 0, 0, 5),
  ],
  happy: [
    cbp(happy1, 0, 0, 3),
    cbp(happy2, 0, 0, 3),
    cbp(happy3, 0, 0, 3),
    cbp(happy4, 0, 0, 3),
  ],
  sad: [
    cbp(sad1, 0, 0, 3),
    cbp(sad2, 0, 0, 3),
    cbp(sad3, 0, 0, 3),
    cbp(sad4, 0, 0, 3),
    cbp(sad5, 0, 0, 3),
  ],
  scared: [],
  surprised: [
    cbp(surprised1, 0, 0, 2.4),
    cbp(surprised2, 0, 0, 2.4),
    cbp(surprised3, 0, 0, 2.4),
    cbp(surprised4, 0, 0, 2.4),
  ],
};

unisexMouths.angry.push(...unisexMouths.sad);
unisexMouths.sad.push(...unisexMouths.angry);
unisexMouths.scared = unisexMouths.surprised;

export default unisexMouths;
