enum Emotion {
  angry = "angry",
  disgusted = "disgusted",
  happy = "happy",
  sad = "sad",
  scared = "scared",
  surprised = "surprised",
}

export default Emotion;
