import twemoji from "react-easy-emoji";
import styled, { CSSProperties } from "styled-components";
import { darken, lighten } from "polished";
import BodyPart from "../interfaces/BodyPart";
import skinColors from "../static/skin-colors";
import { HTMLProps } from "react";

export const GameLayout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

export const Background = styled.div`
  flex-grow: 1;
  position: relative;
  overflow: hidden;
`;

export const ChoiceBox = styled.div`
  background-color: ${({ theme }) => theme.alt};
  box-shadow: 0 0.5rem 2rem ${({ theme }) => theme.shadowColor};
  position: relative;
`;

interface ButtonProps {
  altr?: boolean;
  color?: string;
}

export const Button = styled.button<ButtonProps>`
  overflow: visible;
  padding: 0;
  margin: 0;
  border: none;
  background-color: ${({ theme, altr, color }) =>
    altr ? theme.alt : color ? color : theme.bg};
  font-size: 2rem;
  padding: 1rem;
  color: ${({ theme }) => theme.text};
  font-family: inherit;
  box-shadow: ${({ theme }) => theme.shadow(1)};
  transition-property: background-color, box-shadow;
  transition-duration: 0.2s;
  user-select: none;

  &:hover {
    background-color: ${({ theme, altr, color }) =>
      lighten(0.1, altr ? theme.alt : color ? color : theme.bg)};
    box-shadow: ${({ theme }) => theme.shadow(3)};
  }

  &:active {
    background-color: ${({ theme, altr, color }) =>
      lighten(0.2, altr ? theme.alt : color ? color : theme.bg)};
    box-shadow: ${({ theme }) => theme.shadow(0)};
  }
`;

export const FullscreenOverlay = styled.div`
  z-index: 50;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.shadowColor};
`;

export const Title = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.text};
  font-size: 2.5rem;
  margin: 0.5rem 0;
`;

export const emoji = (input: string) =>
  twemoji(input, {
    baseUrl: "https://twemoji.maxcdn.com/2/svg",
    ext: ".svg",
    size: "",
  });

const BodyPartContainer = styled.div<{ skinColor?: string; accent?: string }>`
  width: 0;
  height: 0;
  position: absolute;

  .accent {
    fill: ${({ accent }) => accent ?? "blue"};
  }

  .skin {
    fill: ${({ skinColor }) => skinColor ?? skinColors[0]};
  }

  .skin-light {
    fill: ${({ skinColor }) => lighten(0.1, skinColor ?? skinColors[0])};
  }

  .skin-lighter {
    fill: ${({ skinColor }) => lighten(0.2, skinColor ?? skinColors[0])};
  }

  .skin-dark {
    fill: ${({ skinColor }) => darken(0.1, skinColor ?? skinColors[0])};
  }

  .skin-darker {
    fill: ${({ skinColor }) => darken(0.2, skinColor ?? skinColors[0])};
  }
`;

export const createBodyPart =
  (
    Image: React.FC<React.HTMLAttributes<SVGSVGElement>> | string,
    x: number,
    y: number,
    width: number
  ): BodyPart =>
  ({ children, skinColor, accent, offset, origin, style }) => {
    const imgStyle: CSSProperties = {
      width: `${width}rem`,
      left: `${x + (offset?.[0] ?? 0)}rem`,
      top: `${y + (offset?.[1] ?? 0)}rem`,
      transform: `translate(-${origin?.[0] ?? 50}%, -${origin?.[1] ?? 50}%)`,
      position: "absolute",
    };
    return (
      <BodyPartContainer style={style} skinColor={skinColor} accent={accent}>
        {typeof Image === "string" ? (
          <img src={Image} alt="body-part" style={imgStyle} />
        ) : (
          <Image style={imgStyle} />
        )}
        {children}
      </BodyPartContainer>
    );
  };
