import { useMemo, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import playerConfigState from "../state/player-config-state";
import pointsState from "../state/points-state";
import { shuffle } from "../utils/math-utils";
import hats from "./BodyParts/hats";
import BodyPartWrapper from "./BodyPartWrapper";
import { BoxView } from "./CharacterView";
import CoinIcon from "./CoinIcon";
import { Button, emoji, Title } from "./common";

const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Option = styled.div<{ isSelected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 7.5rem;
  height: 7.5rem;
  transition-duration: 0.3s;
  transform: scale(${({ isSelected }) => (isSelected ? 1.2 : 1)});

  &::before {
    content: "";
    width: 5.5rem;
    height: 5.2rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.bg};
    position: absolute;
  }
`;

const Buttons = styled.div`
  display: flex;
  > * {
    flex-grow: 1;
  }
`;

interface BuyHatProps {
  onBoxChange: (view: BoxView) => void;
}

const BuyHat: React.FC<BuyHatProps> = ({ onBoxChange }) => {
  const [playerConfig, setPlayerConfig] = useRecoilState(playerConfigState);
  const setPoints = useSetRecoilState(pointsState);

  const [selected, setSelected] = useState(-1);

  const options = useMemo(
    () =>
      shuffle(hats.filter((_, i) => i !== playerConfig.hatIndex)).slice(0, 3),
    []
  );

  const handleYes = () => {
    setPlayerConfig((p) => ({
      ...p,
      hatIndex: hats.findIndex((h) => h === options[selected]) || 0,
    }));
    setPoints((p) => Math.round(p - 100));
    onBoxChange(BoxView.skin);
  };

  const handleNo = () => {
    onBoxChange(BoxView.skin);
  };

  return (
    <div>
      <Title>
        100
        <CoinIcon />
      </Title>
      <Options>
        {options.map((Opt, i) => (
          <Option isSelected={selected === i} onClick={() => setSelected(i)}>
            <BodyPartWrapper>
              <Opt style={{ transform: "scale(0.4)" }} offset={[0, -3.5]} />
            </BodyPartWrapper>
          </Option>
        ))}
      </Options>
      <Buttons>
        {selected >= 0 ? (
          <Button color="#78ff43" onClick={handleYes}>
            {emoji("👍")}
          </Button>
        ) : null}
        <Button color="#ff7843" onClick={handleNo}>
          {emoji("👎")}
        </Button>
      </Buttons>
    </div>
  );
};

export default BuyHat;
