import { useRecoilState, useSetRecoilState } from "recoil";
import roundState from "../state/round-state";
import pointsScreenState from "../state/points-screen-state";
import { FullscreenOverlay } from "./common";
import CountUp from "react-countup";
import pointsBufferState from "../state/points-buffer-state";
import { useEffect, useState } from "react";
import pointsState from "../state/points-state";
import styled from "styled-components";
import CoinIcon from "./CoinIcon";

const Text = styled.span`
  color: white;
  font-size: 4rem;
  text-shadow: ${({ theme }) => theme.shadow(3)};
`;

const PointsScreen: React.FC = () => {
  const setRound = useSetRecoilState(roundState);
  const setPointsScreen = useSetRecoilState(pointsScreenState);
  const [points, setPoints] = useRecoilState(pointsState);
  const [pointsBuffer, setPointsBuffer] = useRecoilState(pointsBufferState);
  const [originalPoints, setOriginalPoints] = useState(0);

  useEffect(() => {
    setOriginalPoints(Math.round(points));
    setPoints((p) => Math.round(p + pointsBuffer));
    setPointsBuffer(0);
  }, []);

  const handleClick = () => {
    setRound((r) => r + 1);
    setPointsScreen(false);
  };

  return (
    <FullscreenOverlay onClick={handleClick}>
      <Text>
        <CountUp start={originalPoints} end={points} duration={1.5} />
        <CoinIcon />
      </Text>
    </FullscreenOverlay>
  );
};

export default PointsScreen;
