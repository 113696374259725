import maleEyebrows from "../components/BodyParts/maleEyebrows";
import maleEyes from "../components/BodyParts/maleEyes";
import unisexMouths from "../components/BodyParts/unisexMouths";
import Emotion from "../enums/Emotion";
import BodyPart from "../interfaces/BodyPart";
import { unique } from "../utils/math-utils";

export interface PartsSet {
  correctParts: BodyPart[];
  wrongParts: BodyPart[];
}

const createRecord = (set: Record<Emotion, BodyPart[]>) => ({
  angry: {
    correctParts: [...set.angry],
    wrongParts: unique([...set.happy, ...set.surprised]),
  },
  disgusted: {
    correctParts: [...set.disgusted],
    wrongParts: unique([...set.happy, ...set.sad]),
  },
  happy: {
    correctParts: [...set.happy],
    wrongParts: unique([
      ...set.angry,
      ...set.disgusted,
      ...set.sad,
      ...set.scared,
    ]),
  },
  sad: {
    correctParts: [...set.sad],
    wrongParts: unique([...set.happy, ...set.angry, ...set.disgusted]),
  },
  scared: {
    correctParts: [...set.scared],
    wrongParts: unique([...set.angry, ...set.happy, ...set.disgusted]),
  },
  surprised: {
    correctParts: [...set.surprised],
    wrongParts: unique([...set.happy, ...set.angry, ...set.disgusted]),
  },
});

export const fbEyes: Record<Emotion, PartsSet> = createRecord(maleEyes);
export const fbEyebrows: Record<Emotion, PartsSet> = createRecord(maleEyebrows);
export const fbMouths: Record<Emotion, PartsSet> = createRecord(unisexMouths);
