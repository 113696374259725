import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled, { css } from "styled-components";
import Emotion from "../enums/Emotion";
import { Background, ChoiceBox, createBodyPart, GameLayout } from "./common";
import Person from "./Person";
import SessionProgress from "./SessionProgress";
import WinScreen from "./WinScreen";
import LoseScreen from "./LoseScreen";
import roundState from "../state/round-state";
import { EmojiMatchRound, FaceBuildRound } from "../interfaces/Games";
import { createEmojiMatchRound, createFaceBuildRound } from "../game/minigames";
import winScreenState from "../state/win-screen-state";
import loseScreenState from "../state/lose-screen-state";
import pointsBufferState from "../state/points-buffer-state";
import { calcReward, createRandomPersonConfig } from "../utils/game-utils";
import pointsScreenState from "../state/points-screen-state";
import PointsScreen from "./PointsScreen";
import PersonConfig from "../interfaces/PersonConfig";
import BodyPart from "../interfaces/BodyPart";
import { fbEyebrows, fbEyes, fbMouths } from "../static/face-build-part-sets";
import gameVersionState from "../state/game-version-state";
import GameVersion from "../enums/GameVersion";
import BodyPartWrapper from "./BodyPartWrapper";

const Choices = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0.5rem;
  gap: 0.25rem;
`;

const ChoiceBtn = styled.button`
  overflow: visible;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: none;
  position: relative;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmojiContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  padding-top: 1rem;
  filter: drop-shadow(${({ theme }) => theme.shadow(2)});
  position: relative;
  z-index: 2;
`;

enum Phase {
  eyebrows,
  mouth,
}

const EmptyPart = createBodyPart(() => <svg />, 0, 0, 0);

const FaceBuild: React.FC = () => {
  const round = useRecoilValue(roundState);
  const [game, setGame] = useState<FaceBuildRound>();
  const [winScreen, setWinScreen] = useRecoilState(winScreenState);
  const [pointsScreen, setPointsScreen] = useRecoilState(pointsScreenState);
  const [loseScreen, setLoseScreen] = useRecoilState(loseScreenState);
  const setPointsBuffer = useSetRecoilState(pointsBufferState);
  const [previousChoices, setPreviousChoices] = useState<Number[]>([]);
  const [highlight, setHighlight] = useState<Emotion>();
  const [personConfig, setPersonConfig] = useState<PersonConfig>();
  const [phase, setPhase] = useState<Phase>(Phase.eyebrows);
  const gameVersion = useRecoilValue(gameVersionState);
  const [canSelect, setCanSelect] = useState(true);

  useEffect(() => {
    const game = createFaceBuildRound(round);
    const pConf: PersonConfig = {
      ...createRandomPersonConfig({
        emotion: game.emotion,
      }),
      Eyebrows: EmptyPart,
      Mouth: EmptyPart,
    };

    setPersonConfig(pConf);
    setGame(game);
    setPhase(Phase.eyebrows);
    setHighlight(undefined);
    setPreviousChoices([]);
    setCanSelect(true);
  }, [round]);

  if (!game || !personConfig) return null;

  const { emotion, eyebrowChoices, eyeChoices, mouthChoices, emoji } = game;

  const getCurrentChoices = () => {
    switch (phase) {
      case Phase.eyebrows:
        return eyebrowChoices;
      case Phase.mouth:
        return mouthChoices;
    }
  };

  const getRightOptions = () => {
    switch (phase) {
      case Phase.eyebrows:
        return fbEyebrows[emotion].correctParts;
      case Phase.mouth:
        return fbMouths[emotion].correctParts;
    }
  };

  const currentChoices = getCurrentChoices();

  const handleChoice = (part: BodyPart) => {
    if (!canSelect) return;
    const answers = getRightOptions();
    if (answers.includes(part)) {
      switch (phase) {
        case Phase.eyebrows:
          setPersonConfig({ ...personConfig, Eyebrows: part });
          return setPhase(Phase.mouth);
        case Phase.mouth:
          setPersonConfig({ ...personConfig, Mouth: part });
          setCanSelect(false);
          setTimeout(() => {
            if (gameVersion === GameVersion.fancy) {
              setPointsBuffer((p) => p + 4);
              if (round >= 29) return setPointsScreen(true);
              else return setWinScreen(true);
            } else {
              setWinScreen(true);
            }
          }, 500);
      }
    } else {
      setLoseScreen(true);
    }
  };

  return (
    <GameLayout>
      <Background>
        <EmojiContainer>{emoji.component}</EmojiContainer>
        {personConfig ? (
          <Person
            notRelative
            config={personConfig}
            style={{ left: "50%", bottom: "-1px" }}
          />
        ) : null}
      </Background>
      <ChoiceBox>
        <SessionProgress />
        <Choices>
          {currentChoices.map((Part) => (
            <ChoiceBtn onClick={() => handleChoice(Part)}>
              <BodyPartWrapper>
                <Part />
              </BodyPartWrapper>
            </ChoiceBtn>
          ))}
        </Choices>
      </ChoiceBox>
      {loseScreen ? (
        <LoseScreen />
      ) : winScreen ? (
        <WinScreen />
      ) : pointsScreen ? (
        <PointsScreen />
      ) : null}
    </GameLayout>
  );
};

export default FaceBuild;
