import { ReactComponent as Body1 } from "../../assets/images/player/bodies/body1.svg";
import { ReactComponent as Clothes1 } from "../../assets/images/player/clothes/clothes1.svg";
import { ReactComponent as Eyes1 } from "../../assets/images/player/eyes/eyes1.svg";
import { ReactComponent as Eyes2 } from "../../assets/images/player/eyes/eyes2.svg";
import { ReactComponent as Eyes3 } from "../../assets/images/player/eyes/eyes3.svg";
import { ReactComponent as Hair1 } from "../../assets/images/player/hair/hair1.svg";
import { ReactComponent as Hair2 } from "../../assets/images/player/hair/hair2.svg";
import { ReactComponent as Angry } from "../../assets/images/player/emotions/angry.svg";
import { ReactComponent as Disgusted } from "../../assets/images/player/emotions/disgusted.svg";
import { ReactComponent as Happy } from "../../assets/images/player/emotions/happy.svg";
import { ReactComponent as Sad } from "../../assets/images/player/emotions/sad.svg";
import { ReactComponent as Scared } from "../../assets/images/player/emotions/scared.svg";
import { ReactComponent as Surprised } from "../../assets/images/player/emotions/surprised.svg";
import { createBodyPart } from "../common";
import Emotion from "../../enums/Emotion";
import BodyPart from "../../interfaces/BodyPart";

const cbp = createBodyPart;

export const playerBody = cbp(Body1, 0, 0, 16);
export const playerClothes = cbp(Clothes1, 0, 0, 12.5);
export const playerEyes = [
  cbp(Eyes1, 0, 0, 8),
  cbp(Eyes2, 0, 0, 8),
  cbp(Eyes3, 0, 0, 6),
];
export const playerHairs = [cbp(Hair1, 0, 0, 13.5), cbp(Hair2, 0, 0, 13.5)];
export const playerFaces: Record<Emotion, BodyPart> = {
  angry: cbp(Angry, 0, 0, 8),
  disgusted: cbp(Disgusted, 0, 0, 8),
  happy: cbp(Happy, 0, 0, 8),
  sad: cbp(Sad, 0, 0, 8),
  scared: cbp(Scared, 0, 0, 8),
  surprised: cbp(Surprised, 0, 0.6, 7.5),
};
