import { ReactComponent as Image1 } from "../../assets/images/faces/unisex/bodies/Asset 2.svg";
import { ReactComponent as Image2 } from "../../assets/images/faces/unisex/bodies/Asset 4.svg";
import { ReactComponent as Image3 } from "../../assets/images/faces/unisex/bodies/Asset 5.svg";
import { ReactComponent as Image4 } from "../../assets/images/faces/unisex/bodies/Asset 6.svg";
import { ReactComponent as Image5 } from "../../assets/images/faces/unisex/bodies/Asset 7.svg";
import { ReactComponent as Image6 } from "../../assets/images/faces/unisex/bodies/Asset 8.svg";
import { ReactComponent as Image7 } from "../../assets/images/faces/unisex/bodies/Asset 9.svg";
import { ReactComponent as Image8 } from "../../assets/images/faces/unisex/bodies/Asset 10.svg";
import { ReactComponent as Image9 } from "../../assets/images/faces/unisex/bodies/Asset 11.svg";
import { ReactComponent as Image10 } from "../../assets/images/faces/unisex/bodies/Asset 12.svg";
import { createBodyPart } from "../common";
import BodyPart from "../../interfaces/BodyPart";

const cbp = createBodyPart;

const unisexBodies: [BodyPart, number][] = [
  [cbp(Image1, 0, 0, 16), 0],
  [cbp(Image2, 0, 0, 17), -0.4],
  [cbp(Image3, 0, 0, 16), -0.5],
  [cbp(Image4, 0, 0.75, 16), -0.4],
  [cbp(Image5, 0, 0, 16), -1.1],
  [cbp(Image6, 0, 0, 16), 0],
  [cbp(Image7, 0, 0, 16), -0.4],
  [cbp(Image8, 0, 1, 16), -1.7],
  [cbp(Image9, 0, 1.75, 16), -1.8],
  [cbp(Image10, 0, 0, 16), 0.75],
];

export default unisexBodies;

export const unisexBodyHairOffset: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
