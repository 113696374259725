import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled, { css } from "styled-components";
import Emotion from "../enums/Emotion";
import { Background, ChoiceBox, GameLayout } from "./common";
import Person from "./Person";
import SessionProgress from "./SessionProgress";
import WinScreen from "./WinScreen";
import LoseScreen from "./LoseScreen";
import roundState from "../state/round-state";
import { EmojiMatchRound } from "../interfaces/Games";
import { createEmojiMatchRound } from "../game/minigames";
import winScreenState from "../state/win-screen-state";
import loseScreenState from "../state/lose-screen-state";
import pointsBufferState from "../state/points-buffer-state";
import { calcReward, createRandomPersonConfig } from "../utils/game-utils";
import pointsScreenState from "../state/points-screen-state";
import PointsScreen from "./PointsScreen";
import PersonConfig from "../interfaces/PersonConfig";
import GameVersion from "../enums/GameVersion";
import gameVersionState from "../state/game-version-state";

const Choices = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0.5rem;
  gap: 0.25rem;
`;

const EmojiButton = styled.button<{ highlight?: boolean }>`
  overflow: visible;
  padding: 0;
  margin: 0;
  font-size: 6rem;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  > * {
    transition: transform 0.3s, filter 0.3s;
    filter: drop-shadow(${({ theme }) => theme.shadow(2)});
  }

  ${(props) =>
    props.highlight
      ? css`
          > * {
            transform: scale(1.2);
            filter: drop-shadow(${({ theme }) => theme.shadow(5)})
              brightness(1.2);
          }
        `
      : css`
          &:disabled {
            filter: saturate(0);
          }

          &:hover:enabled > * {
            transform: scale(1.05);
            filter: drop-shadow(${({ theme }) => theme.shadow(3)})
              brightness(1.1);
          }
        `}
`;

const EmojiMatch: React.FC = () => {
  const round = useRecoilValue(roundState);
  const [game, setGame] = useState<EmojiMatchRound>();
  const [winScreen, setWinScreen] = useRecoilState(winScreenState);
  const [pointsScreen, setPointsScreen] = useRecoilState(pointsScreenState);
  const [loseScreen, setLoseScreen] = useRecoilState(loseScreenState);
  const setPointsBuffer = useSetRecoilState(pointsBufferState);
  const [previousChoices, setPreviousChoices] = useState<Emotion[]>([]);
  const [highlight, setHighlight] = useState<Emotion>();
  const [personConfig, setPersonConfig] = useState<PersonConfig>();
  const gameVersion = useRecoilValue(gameVersionState);

  useEffect(() => {
    const game = createEmojiMatchRound(round);
    setGame(game);
    setPersonConfig(
      createRandomPersonConfig({
        emotion: game.choices[game.answerIndex].emotion,
        holdItem: gameVersion === GameVersion.fancy && round % 2 !== 0,
      })
    );
    setHighlight(undefined);
    setPreviousChoices([]);
  }, [round]);

  if (!game) return null;

  const { choices, answerIndex } = game;

  const handleChoice = (emotion: Emotion) => {
    const won = choices.findIndex((e) => e.emotion === emotion) === answerIndex;

    if (won) {
      setHighlight(emotion);

      if (gameVersion === GameVersion.fancy) {
        if (round === 3 || round === 7 || round === 11) setPointsScreen(true);
        else setWinScreen(true);

        setPointsBuffer(
          (p) => p + calcReward(choices.length, previousChoices.length)
        );
      } else {
        setWinScreen(true);
      }
    } else {
      setLoseScreen(true);
      setPreviousChoices((p) => [...p, emotion]);
    }
  };

  const anyScreen = loseScreen || winScreen || pointsScreen;

  return (
    <GameLayout>
      <Background>
        {personConfig ? (
          <Person
            config={personConfig}
            style={{ left: "50%", bottom: "-1px" }}
          />
        ) : null}
      </Background>
      <ChoiceBox>
        <SessionProgress />
        <Choices>
          {choices.map(({ emotion, component }) => (
            <EmojiButton
              disabled={anyScreen || previousChoices.includes(emotion)}
              onClick={() => handleChoice(emotion)}
              highlight={highlight === emotion}
              key={emotion}
            >
              {component}
            </EmojiButton>
          ))}
        </Choices>
      </ChoiceBox>
      {loseScreen ? (
        <LoseScreen />
      ) : winScreen ? (
        <WinScreen />
      ) : pointsScreen ? (
        <PointsScreen />
      ) : null}
    </GameLayout>
  );
};

export default EmojiMatch;
