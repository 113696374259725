import { useRecoilState, useSetRecoilState } from "recoil";
import styled, { css } from "styled-components";
import Emotion from "../enums/Emotion";
import View from "../enums/View";
import DiaryEntry from "../interfaces/DiaryEntry";
import diaryState from "../state/diary-state";
import inGameLoopState from "../state/in-game-loop-state";
import viewState from "../state/view-state";
import diaryEmojis from "../static/diary-emojis";
import { isToday, last, range } from "../utils/math-utils";
import { Button, ChoiceBox } from "./common";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  h1,
  h2 {
    color: ${({ theme }) => theme.text};
    text-align: center;
  }
`;

const CalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const Calendar = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.25rem;
  max-height: 100%;
  max-width: 100%;
`;

const Cell = styled.div`
  background-color: ${({ theme }) => theme.alt};
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: ${({ theme }) => theme.shadow(0.5)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  img {
    margin: 0 !important;
  }
`;

const ChoiceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
`;

const ChoiceBtn = styled.button<{ highlight?: boolean }>`
  overflow: visible;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  font-size: 5rem;
  width: 100%;
  img {
    transition-duration: 0.2s;
  }
  ${({ highlight }) =>
    highlight
      ? css`
          img {
            transform: scale(1.25);
            filter: drop-shadow(${({ theme }) => theme.shadow(2)});
          }
        `
      : ""};
`;

const EmotionChoiceBox = styled(ChoiceBox)`
  padding: 0 0.5rem 1rem;
  display: flex;
  flex-direction: column;
`;

const DiaryView: React.FC = () => {
  const setView = useSetRecoilState(viewState);
  const setInGameLoop = useSetRecoilState(inGameLoopState);
  const [diary, setDiary] = useRecoilState(diaryState);

  const oldEntry = last(diary);
  const oldEntryIsToday = oldEntry && isToday(new Date(oldEntry.time));

  const handleClick = (emotion: Emotion) => {
    const newEntry: DiaryEntry = { emotion, time: new Date().getTime() };
    if (diary.length >= 28) {
      setDiary([newEntry]);
    } else if (oldEntryIsToday) {
      setDiary([...diary.slice(0, -1), newEntry]);
    } else {
      setDiary([...diary, newEntry]);
    }
  };

  const handleHomeButton = () => {
    setInGameLoop(false);
    setView(View.mainMenu);
  };

  return (
    <Container>
      <h1>Mood diary</h1>
      <CalContainer>
        <Calendar>
          {range(28).map((i) => (
            <Cell>
              {diary[i] ? diaryEmojis[diary[i].emotion].component : null}
            </Cell>
          ))}
        </Calendar>
      </CalContainer>
      <EmotionChoiceBox>
        <h2>Today I feel...</h2>
        <ChoiceGrid>
          {Object.values(diaryEmojis).map((e) => (
            <ChoiceBtn
              highlight={
                oldEntry && oldEntryIsToday && e.emotion === oldEntry.emotion
              }
              onClick={() => handleClick(e.emotion)}
            >
              {e.component}
            </ChoiceBtn>
          ))}
        </ChoiceGrid>
        <Button onClick={handleHomeButton}>Back</Button>
      </EmotionChoiceBox>
    </Container>
  );
};

export default DiaryView;
