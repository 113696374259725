import angry1 from "../../assets/images/faces/male/eyes/angry/Asset 19.png";
import angry2 from "../../assets/images/faces/male/eyes/angry/Asset 21.png";
import angry3 from "../../assets/images/faces/male/eyes/angry/Asset 24.png";
import angry4 from "../../assets/images/faces/male/eyes/angry/Asset 26.png";
import { ReactComponent as angry5 } from "../../assets/images/faces/male/eyes/angry/Asset 30.svg";
import disgusted1 from "../../assets/images/faces/male/eyes/disgusted/Asset 22.png";
import disgusted2 from "../../assets/images/faces/male/eyes/disgusted/Asset 23.png";
import disgusted3 from "../../assets/images/faces/male/eyes/disgusted/Asset 24.png";
import happy1 from "../../assets/images/faces/male/eyes/happy/Asset 19.png";
import happy2 from "../../assets/images/faces/male/eyes/happy/Asset 21.png";
import happy3 from "../../assets/images/faces/male/eyes/happy/Asset 25.png";
import happy4 from "../../assets/images/faces/male/eyes/happy/Asset 27.png";
import { ReactComponent as happy5 } from "../../assets/images/faces/male/eyes/happy/Asset 30.svg";
import { ReactComponent as happy6 } from "../../assets/images/faces/male/eyes/happy/Asset 31.svg";
import sad1 from "../../assets/images/faces/male/eyes/sad/Asset 17.png";
import sad2 from "../../assets/images/faces/male/eyes/sad/Asset 21.png";
import sad3 from "../../assets/images/faces/male/eyes/sad/Asset 24 (2).png";
import sad4 from "../../assets/images/faces/male/eyes/sad/Asset 28.png";
import { ReactComponent as sad5 } from "../../assets/images/faces/male/eyes/sad/Asset 33.svg";
import surprised1 from "../../assets/images/faces/male/eyes/surprised/Asset 19.png";
import surprised2 from "../../assets/images/faces/male/eyes/surprised/Asset 20.png";
import surprised3 from "../../assets/images/faces/male/eyes/surprised/Asset 21.png";
import { ReactComponent as surprised4 } from "../../assets/images/faces/male/eyes/surprised/Asset 29.svg";
import { ReactComponent as uni1 } from "../../assets/images/faces/male/eyes/universal/Asset 35.svg";
import { ReactComponent as uni2 } from "../../assets/images/faces/male/eyes/universal/Asset 36.svg";
import { ReactComponent as uni3 } from "../../assets/images/faces/male/eyes/universal/Asset 37.svg";
import { ReactComponent as funi1 } from "../../assets/images/faces/female/eyes/universal/40.svg";
import { ReactComponent as funi2 } from "../../assets/images/faces/female/eyes/universal/Asset 37.svg";
import { ReactComponent as funi3 } from "../../assets/images/faces/female/eyes/universal/Asset 38.svg";
import Emotion from "../../enums/Emotion";
import BodyPart from "../../interfaces/BodyPart";
import { createBodyPart } from "../common";

const cbp = createBodyPart;

const w = 8;

const unis = [
  cbp(uni1, 0, 0, w),
  cbp(uni2, 0, 0, w),
  cbp(uni3, 0, 0, w),
  cbp(funi1, 0, 0, w),
  cbp(funi2, 0, 0, w),
  cbp(funi3, 0, 0, w),
];

const maleEyes: Record<Emotion, BodyPart[]> = {
  angry: [
    cbp(angry1, 0, 0, w),
    cbp(angry2, 0, 0, w),
    cbp(angry3, 0, 0, w),
    cbp(angry4, 0, 0, w),
    cbp(angry5, 0, 0, w),
    ...unis,
  ],
  disgusted: [
    cbp(disgusted1, 0, 0, w),
    cbp(disgusted2, 0, 0, w),
    cbp(disgusted3, 0, 0, w),
  ],
  happy: [
    cbp(happy1, 0, 0, w),
    cbp(happy2, 0, 0, w),
    cbp(happy3, 0, 0, w),
    cbp(happy4, 0, 0, w),
    cbp(happy5, 0, 0, w),
    cbp(happy6, 0, 0, w),
    ...unis,
  ],
  sad: [
    cbp(sad1, 0, 0, w),
    cbp(sad2, 0, 0, w),
    cbp(sad3, 0, 0, w),
    cbp(sad4, 0, 0, w),
    cbp(sad5, 0, 0, w),
  ],
  scared: [],
  surprised: [
    cbp(surprised1, 0, 0, w),
    cbp(surprised2, 0, 0, w),
    cbp(surprised3, 0, 0, w),
    cbp(surprised4, 0, 0, w),
  ],
};

maleEyes.scared = maleEyes.sad;

export default maleEyes;
