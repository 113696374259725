import DiaryEntry from "../interfaces/DiaryEntry";
import PersonConfig from "../interfaces/PersonConfig";
import PlayerConfig from "../interfaces/PlayerConfig";

export const savePoints = (points: number) => {
  localStorage.setItem("points", Math.round(points).toString());
};

export const loadPoints = (): number => {
  return parseInt(localStorage.getItem("points") ?? "0");
};

export const saveDiary = (diary: DiaryEntry[]) => {
  localStorage.setItem("diary", JSON.stringify(diary));
};

export const loadDiary = (): DiaryEntry[] => {
  const stored = localStorage.getItem("diary");
  if (!stored) return [];
  const diary = JSON.parse(stored);
  return Array.isArray(diary) ? diary : [];
};

export const savePlayerConfig = (config: PlayerConfig) => {
  localStorage.setItem("playerConfig", JSON.stringify(config));
};

export const loadPlayerConfig = (): PlayerConfig | undefined => {
  const stored = localStorage.getItem("playerConfig");
  if (!stored) return undefined;
  return JSON.parse(stored);
};
